@import "https://fonts.googleapis.com/css?family=Quicksand";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "lib/assets/scss/colors.scss";
@import "lib/assets/scss/sizes.scss";
@import '@angular/cdk/overlay-prebuilt.css';


html,
body {
    margin: 0;
    font-family: 'Helvetica';
    font-size: $default-font;

    span,
    h2 {
        font-family: 'Helvetica';
    }

    p,
    span,
    li {
        line-height: $large-line-height;
    }

    button,
    a {
        font-family: 'Helvetica';
        font-size: 16px;
    }

    .mdc-form-field {
        font-family: 'Helvetica';
    }

    h5 {
        font-size: $default-font;
    }

    .backgroundblur {
        background: #413c3fcc;
        opacity: 0.5;
        overflow: auto;
    }

    .cdk-global-overlay-wrapper {
        overflow: auto;
        pointer-events: auto;
    }

    .mat-drawer-inner-container {
        //margin-top:16px;

    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        background:$regenero-light-orange;
    }

}

.NgxEditor__Content {
    height: 300px;
    overflow-y: scroll;
}

.mdc-form-field {
    width:150px;
}

pw-doctor,
pw-home,
pw-healthfund,
pw-pricelist,
pw-consultation {
    width: 90%;
}

pw-specialitydoctors {
    width: $content-max-size;
}

@media(max-width:$tablet-view) {
    .cdk-overlay-pane {
        //background: white;
        font-size: $small-font;
        opacity: 0.9;



    }



}
